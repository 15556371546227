import React from 'react';
import { useParams } from 'react-router-dom';
import { deformatString, removeAfterUnderscore } from '../../../Common';
import "./productdetail.style.css";
import {Products} from "../../../Api";
import { Col, Row, Container, Table, Tab, Tabs } from 'react-bootstrap';

const ProductDetail = () => {
    const {name,category} = useParams();

    // Finding Product
    const product = Products[category]?.find((item) => item.name == deformatString(name));

    if (!product) {
        return <h2 style={{ color: "red" }}>Product not found!</h2>;
    }

    return (
        <>
            <div className='pageTitle'>
                <h4>{product.name}</h4>
            </div>
            <div className='productContent'>
                <Container>
                    <Row>
                        <Col lg={4}>
                            <div className='brandImage'>

                                <img src={product.thumbnail} />
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className='productContentWrepper'>
                                <h1 className='productName'>{product.headline}</h1>
                                <span className='productTagline'>{product.tag}</span>
                                <p className='productDesc' dangerouslySetInnerHTML={{ __html: product.desc }} ></p>
                                <span className='productCtg'> <b>Category:</b> {product.ctg}</span>

                                <div className='packaging'>
                                    {/* {console.log(product.packing)} */}
                                    <Tabs
                                        defaultActiveKey="profile"
                                        id="justify-tab-example"
                                        className="mb-3"
                                        justify

                                    >
                                    {Object.entries(product.packing).map(([category, items])=>{
                                        return (
                                            <Tab className='productTabs' eventKey={category} title={category}>
                                                <div className='productTabContent' key={category}>
                                                    <Table className='productTable'>
                                                        {console.log(items)}
                                                        {Object.entries(items).map(([key, value], index) => (
                                                            <tr key={index}>
                                                                <th>{removeAfterUnderscore(key)}</th>
                                                                <td>{value}</td>
                                                            </tr>
                                                        ))}
                                                    </Table>
                                                </div>
                                            </Tab>
                                        )
                                    })}
                                    </Tabs>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ProductDetail
