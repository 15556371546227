import { Outlet } from 'react-router-dom';
import {images, icons, COLORS} from '../../constants'
import { NavigationBar, Footer } from '../../Components';
import 'bootstrap/dist/css/bootstrap.min.css';


function Layout() {
  return (
    <div>
      <header>
        <NavigationBar />
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Layout;
