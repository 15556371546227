import heart from "../assets/icons/heart.png";
import menu from "../assets/icons/menu.png";
import search from "../assets/icons/search.png";
import filter from "../assets/icons/filter.png";
import left from "../assets/icons/left.png";
import heartOutline from "../assets/icons/heart-ol.png";
import share from "../assets/icons/share.png";
import location from "../assets/icons/location.png";
import chevronLeft from '../assets/icons/chevron-left.png'
import chevronRight from '../assets/icons/chevron-right.png'
import play from '../assets/icons/play.png'
import google from '../assets/icons/google.png'
import facebook from '../assets/icons/facebook.png'
import instagram from '../assets/icons/instagram.png'

export default {
  heart,
  menu,
  search,
  filter,
  left,
  heartOutline,
  share,
  location,
  chevronLeft,
  chevronRight,
  play,
  google,
  facebook,
  instagram,
  
};
