import React from 'react'
import { Heading, TestimonialCard } from "../../Components";
import { Customers} from "../../Api";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container } from 'react-bootstrap';
import "./testimonial.style.css";
import 'swiper/css';

import { Autoplay, EffectFade } from 'swiper/modules';

const Testimonial = (props) => {
  return (
      <div className="testimonial">
            <Container>
                <Heading title={props.title} />
            </Container>
            <div className="slide justify-content-center">
                <Swiper
                    slidesPerView={props.slides}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                      }}
                      breakpoints={props.breakpoints}
                    loop={true}
                    spaceBetween={props.space}
                    centeredSlides={true}
                    modules={[Autoplay, EffectFade]}
                    className="mySwiper"
                >
                    
                    {Customers.map((data, index) => {
                        return ( 
                            <SwiperSlide>
                                <TestimonialCard image={data.profile} name={data.name} quote={data.quote} tag={data.tag} key={index} wrap={props.wrap} />
                            </SwiperSlide>
                        )
                    })}
                    
                </Swiper>
            </div>
        </div>
  )
}

export default Testimonial
