import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
// import SendIcon from '@mui/icons-material/Send';

import Button from '@mui/material/Button';
import "./contact.style.css";

const Contact = () => {
  return (
    <div className='contact'>
      <div className='pageTitle'>
        <h4>Contact</h4>
      </div>
      <div className='content'>
        <Container>
            <Row>
                <Col lg={6}>
                    <div className='map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.713156974923!2d68.28151917505676!3d25.3474046776146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x394c7b1da5204a37%3A0xdb45d2fb9a549b31!2sPakistan%20Oil%20Mills%20(Pvt.)%20Ltd!5e0!3m2!1sen!2s!4v1742897612617!5m2!1sen!2s" width="100%" height="100%"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                </Col>
                <Col lg={6}>
                    <div className='form'>
                        <h1>Hava a Talk!</h1>
                        <form action={"#"} method='POST'>
                            <Row>
                                <Col lg={12}>
                                    <div className="input d-flex align-items-center ">
                                        {/* <label>Name : </label> */}
                                        {/* <FormControl variant="filled"> */}
                                            {/* <InputLabel htmlFor="component-filled">Name</InputLabel> */}
                                            <TextField
                                                required
                                                id="standard-required"
                                                label="Name"
                                                fullWidth
                                                variant="filled"
                                                />
                                        {/* </FormControl> */}

                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <div className="input d-flex align-items-center ">
                                        {/* <label>Email : </label> */}
                                        {/* <FormControl variant="filled"> */}
                                            {/* <InputLabel htmlFor="component-filled">Email</InputLabel> */}
                                            <TextField
                                                required
                                                fullWidth
                                                id="standard-required"
                                                label="Email"
                                                variant="filled"
                                                />
                                        {/* </FormControl> */}
                                        
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <div className="input d-flex align-items-center ">
                                        {/* <label>Message : </label> */}
                                        {/* <FormControl variant="filled"> */}
                                            {/* <InputLabel htmlFor="component-filled">Message</InputLabel> */}
                                            <TextField
                                                id="standard-multiline-static"
                                                label="Message"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                // defaultValue="Default Value"
                                                variant="filled"
                                                />
                                        {/* </FormControl> */}
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="input d-flex align-items-center ">
                                    <Button variant="contained" fullWidth>
                                        Submit
                                    </Button>
                                    </div>
                                </Col>
                            </Row>

                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
    </div>
  )
}

export default Contact
