import React from 'react';
import "./heading.style.css";

const heading = (props) => {
  return (
    <div className='heading'>
        <h3>{props.title}</h3>
    </div>
  )
}
export default heading;