import React from 'react';
import "./testimonialcard.style.css";
import { images } from '../../constants';
import { Row, Col } from 'react-bootstrap';

const TestimonialCard = (props) => {
  return (
    <Col  className={`testimonialCardWrepper ${props.wrap ? "extend" : ""}`} >
        <Row className="justify-content-center">
            <Col lg={props.wrap ? 12 : 3} className='' >
                <div className="image mb-3">
                    <img src={props.image} />
                </div>
            </Col>
            <Col lg={props.wrap ? 12 : 8} className="pl-4">
                <div className="content">
                    <p className="quote">{props.quote}</p>
                    <h2 className="name">{props.name}</h2>
                    <span className="profession">{props.tag}</span>
                </div>
            </Col>

        </Row>
    </Col>
  )
}

export default TestimonialCard
