import { images } from "../constants";

const customers = [
    {
        name: "Pervez Mallah",
        quote: "\"lt's Brand Naz Cooking Oil and Naz Banaspati is one of the favourite brand in sindh , Pakistan\"",
        tag: "Customer : Sakrand",
        profile: images.avatar1,

    },
    {
        name: "Nazeer Ahmed",
        quote: "\"We are thankful to Pakistan Oil Mills for its best quality and healthy products.We proud to work with POM\"",
        tag: "Customer : Sakrand",
        profile: images.avatar2,

    },
    {
        name: "Bilal Traders",
        quote: "\"The home of the quality products. Bandhay Dilon Ke Bandhan Pure Cooking Oil and Pure Banaspati.\"",
        tag: "Customer : Hyderabad",
        profile: images.avatar3,

    },
    {
        name: "Maryam Traders",
        quote: "\"Sehat ki baat Pure or Naz Ke Saath. Munasib Qeemat me or hifzane sehat ke usool ke mutabiq.\"",
        tag: "Customer : Hyderabad",
        profile: images.avatar2,

    }
];

export default customers;