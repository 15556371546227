import React from 'react';
import "./card.style.css";
import { images } from '../../constants';
import { Link } from 'react-router-dom';

const Card = (props) => {
  return (
    <div className='cardWrepper'>
      <div className='cardImage'>
        <img src={props.image} />
      </div>
      <div className='cardContent'>
        <p className='title'>{props.name}</p>
        <span className='tag'>{props.tagline}</span>
      </div>
      <div className="cardFooter">
            <Link to={props.link}>
                <button>Learn More</button>
            </Link>
        </div>
    </div>
  )
}

export default Card
