import './navigation.css';
import { Navbar, Nav, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from '../../constants';



function NavigationBar() {
  return (
    <Navbar expand="lg" className="navBar">
      <Container >
        <Nav className="linksContainer align-items-center">
            <Nav.Link href="/" className='link'>Home</Nav.Link>
            <Nav.Link href="/about" className='link'>About</Nav.Link>
            <Nav.Link href="/products" className='link'>Products</Nav.Link>
          </Nav>
        <Navbar.Brand href="/" className="logo"> <img src={images.logo2} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Nav className="linksContainer justify-content-end align-items-center">
            <Nav.Link href="/career" className='link'>Career</Nav.Link>
            <Nav.Link href="/contact" className='link'>Contact</Nav.Link>
            <Nav.Link href="/login" className='link btn btn-nav'>Login</Nav.Link>
          </Nav>
        {/* <Navbar.Collapse id="basic-navbar-nav" className='d-none'>
          <Nav className="ms-auto">
            <Nav.Link href="/" className='link'>Home</Nav.Link>
            <Nav.Link href="/about" className='link'>About</Nav.Link>
            <Nav.Link href="/products" className='link'>Products</Nav.Link>
            <Nav.Link href="/contact" className='link'>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
      <img src={images.wave} className='wave' />
    </Navbar>
  );
}

export default NavigationBar;
