function formatString(str) {
    return str.toLowerCase().replace(/\s+/g, '-');
}

function deformatString(str) {
    return str.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());;
}

const removeAfterUnderscore = (str) => str.split("_")[0];
  
export {formatString, deformatString, removeAfterUnderscore};
  