import { images } from "../../constants";
import "./about.style.css";
import { Container, Row, Col } from "react-bootstrap";
import { Testimonial } from "../../Components";

const About = () => {
    return(
        <>
            <div className="about">
                <div className="pageTitle mb-5">
                    <h4>Who We Are</h4>
                </div >
                <Container>
                    <Row>
                        <Col lg={6} >
                            <div className="imageContainer">
                            <img src={images.banner1} />
                            <img src={images.banner3} />
                            </div>
                        </Col>
                        <Col lg={6} >
                        <div className="aboutContent">
                            <p className="heading">About</p>
                            <h1>Pakistan Oil Mills</h1>
                            <p>Pakistan Oil Mills which comes under the umbrella of highly successful , responsive and dynamic group of companies, which invest in building wining brands that continuously seek to create value for the customers.</p>
                            <span>Kotri, Hyderabad - Pakistan</span>
                            <h3>+92 22 3871100-1700</h3>
                            </div>
                        </Col>
                    </Row>
                    <div className="statics">
                        <Row>
                            <Col lg={2}>
                                <h3>31+</h3>
                                <p>Years of Experience</p>
                            </Col>
                            <Col lg={2}>
                                <h3>10+</h3>
                                <p>Products</p>
                            </Col>
                            <Col lg={2}>
                                <h3>1000+</h3>
                                <p>Staffs</p>
                            </Col>
                            <Col lg={2}>
                                <h3>100000+</h3>
                                <p>Happy Customers</p>
                            </Col>
                            <Col lg={4}>
                                <p className="desc">
                                    Pakistan Oil Mills Products Are Prepared Strictly In Accordance With The Pakistan Standard Quality Control Authority.
                                </p>
                            </Col>
                        </Row>
                    </div>
                
                </Container>

                <div className="customers">
                    <Container>
                        <h1>Happy Customers</h1>
                        <Testimonial slides={3} space={20} wrap={1} />
                    </Container>
                </div>

                <div className="certification">
                    <Container fluid={"xl"}>
                        <div className="contentWrepper">
                        <h1>
                            About The <span>Pakistan Oil Mills!</span>   
                        </h1>
                        <Row>
                            <Col lg={8}>
                                <div className="context">
                                    
                                    <p>
                                    Pakistan Oil Mills Produce The Best Quality Cooking Oil, Banaspati Made On Latest Electronic Plant Under Scientifically Supervised Hygienic Conditions With Strict Quality Control Methods, Contains Vitamins A,D & E. Best Packing Material Is Used In Its Production. Pure Cooking Oil And Banaspati Is A Product For Quality Concious People.
                                    <br />
                                    <br />
                                    Pakistan Oil Mills Products Are Prepared Strictly In Accordance With The Pakistan Standard Quality Control Authority.
                                    <br />
                                    <br />
                                    Pakistan Oil Mills Has Vast Experience In Edible Oil Business , It Has Established In 1990 With Well Equipped Composite Vegetable Oil And Banaspati Manufacturing Unit And Is Gaining Good Market Share.
                                    <br />
                                    <br />
                                    Pakistan Oil Mills Which Comes Under The Umbrella Of Highly Successful , Responsive And Dynamic Group Of Companies, Which Invest In Building Wining Brands That Continuously Seek To Create Value For The Customers. Pakistan Oil Mills Produce The Best Quality Cooking Oil, Banaspati Made On Latest Electronic Plant Under Scientifically Supervised Hygienic Conditions With Strict Quality Control Methods, Contains Vitamins A,D & E. Best Packing Material Is Used In Its Production. Pure Cooking Oil And Banaspati Is A Product For Quality Concious People. Pakistan Oil Mills Products Are Prepared Strictly In Accordance With The Pakistan Standard Quality Control Authority. Pakistan Oil Mills Has Vast Experience In Edible Oil Business , It Has Established In 1990 With Well Equipped Composite Vegetable Oil And Banaspati Manufacturing Unit And Is Gaining Good Market Share.
                                    {/* <br /><br /> */}
                                    </p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="line">
                                    <div>
                                    <img src={images.certificate1} />
                                    </div>
                                    <div>
                                    <img src={images.certificate2} />
                                    </div>
                                </div>
                                <div className="line centered">
                                    <div>
                                    <img src={images.certificate3} />
                                    </div>
                                    <div>
                                    <img src={images.certificate4} />
                                    </div>
                                </div>
                                <div className="line">
                                    <div>
                                    <img src={images.certificate5} />
                                    </div>
                                    <div>
                                    <img src={images.certificate6} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        </div>
                    </Container>
                </div>
            </div>
        </>

    )
}

export default About;